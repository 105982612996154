import React from "react"
import { FormattedMessage as I18n } from "react-intl"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const Container = styled.div`
    padding-right: 8vw;
    padding-left: 8vw;
    padding-top: 75px;
    text-align: center;
    background-color: var(--${props => props.color});
    color: var(--${props => props.text});
    z-index: 10;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    padding-bottom: 8vw;

    div {
        flex-basis: 50%;
        max-height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 20px;

        & > .video {
            width: 100%;
            height: 60vh;
            padding-top: 0;
        }
    }

    a {
        color: inherit;
    }

    p {
        color: inherit;
        font-size: 24px;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        margin: 0 0 20px 40px;
    }
`

const SectionName = styled.h1`
    font-weight: 700;
    text-align: center;
    text-transform: lowercase;

    span {
        display: inline-block;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: inherit;
        margin-bottom: 40px;

        &:before,
        &:after {
            content: '';
            display: inline-block;
            width: 100%;
            border-top: 8px solid;
        }

        &:before { margin-bottom: 0.25em; }
        &:after { margin-top: 0.25em; }
`

export default function Services() {
    const data = useStaticQuery(
        graphql`
            query {
                dataJson {
                    producer {
                        name
                        lien
                    }
                    arranger {
                        name
                        lien
                    }
                    musicien
                    musicien_video
                }
                cover_producer: file(relativePath: {eq: "images/cover/akatche_composer.jpg"}) {
                    childImageSharp {
                        fluid (maxHeight: 800, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                cover_arranger: file(relativePath: {eq: "images/cover/akatche_arranger.jpg"}) {
                    childImageSharp {
                        fluid (maxHeight: 800, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                cover_musician: file(relativePath: {eq: "images/cover/akatche_live_musician_image.jpg"}) {
                    childImageSharp {
                        fluid (maxHeight: 800, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        `
    )

    return (
        <>
            <Container id="producer" color="light" text="dark">
                <SectionName>
                    <span><I18n id="service.title1" defaultMessage={"a leading"} /></span>
                </SectionName>
                <Grid>
                    <div>
                        <Img imgStyle={{ objectFit: "scale-down", objectPosition: "50% top" }} fluid={data.cover_producer.childImageSharp.fluid} alt="cover from aya nakamura album" />
                    </div>
                    <div>
                        { data.dataJson.producer.map(collab => (
                            <OutboundLink key={collab.name} aria-label={`${collab.name} and akatche production music collaboration`} href={collab.lien} rel="noreferrer" target="_blank">
                                <p className="o-custom-link item">{collab.name}</p>
                            </OutboundLink>
                        )) }
                    </div>
                </Grid>
            </Container>
            <Container id="arranger" color="dark" text="light">
                <SectionName>
                    <span><I18n id="service.title2" defaultMessage={"a leading"} /></span>
                </SectionName>
                <Grid>
                    <div>
                        <Img imgStyle={{ objectFit: "scale-down", objectPosition: "50% top" }}  fluid={data.cover_arranger.childImageSharp.fluid} alt="cover from aya nakamura album" />
                    </div>
                    <div>
                        { data.dataJson.arranger.map(collab => (
                            <OutboundLink key={collab.name} aria-label={`${collab.name} and akatche arranger music collaboration`} href={collab.lien} rel="noreferrer" target="_blank">
                                <p className="o-custom-link item">{collab.name}</p>
                            </OutboundLink>
                        )) }
                    </div>
                </Grid>
            </Container>
            <Container id="musicien" color="light" text="dark">
                <SectionName>
                    <span><I18n id="service.title3" defaultMessage={"a leading"} /></span>
                </SectionName>
                <Grid>
                    <div>
                        <div className="video">
                            <iframe title="Akatche on live" src={data.dataJson.musicien_video} height={"100%"} />
                        </div>
                    </div>
                    <div>
                        { data.dataJson.musicien.map((name, index) => (
                            <p key={index}>{name}</p>
                        )) }
                    </div>
                </Grid>
            </Container>
        </>
    )
}
