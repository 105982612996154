import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import Buttons from "../components/Buttons"

const Title_screen = styled.div`
    position: relative;
    overflow: hidden;
    background-color: var(--dark);
    height: 100vh;
    width: 100%;

    & > .image {
        position: relative;
        margin-top 58px;
        object-fit: cover;
    }

    & > .video {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
    }
`

const Content = styled.div`
    top: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    color: var(--light);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0;

    div {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 56px;

        @media screen and (min-width: 760px) {
            background-color: var(--dark);
            padding-bottom: 10px;
        }
    }
`

export default function Title() {
    let screen
    if (typeof window !== "undefined") {
        screen = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }

    const [size, setSize] = React.useState(screen)

    const listenResizeEvent = event => {
      setSize(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
    }

    React.useEffect(() => {
        window.addEventListener("resize", listenResizeEvent)

        return () =>
            window.removeEventListener("resize", listenResizeEvent)
    }, [])

    const data = useStaticQuery(
        graphql`
            query {
                dataJson {
                    embed
                }
                image: file(relativePath: {eq: "images/akatche_home.jpg"}) {
                    childImageSharp {
                        fluid (maxHeight: 800, maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `
    )

    function ElementToRender() {
        if (size < 760) {
            return (
                <Img
                    className="image"
                    fluid={data.image.childImageSharp.fluid}
                    alt="akatche in front of a piano" />
            )
        } else {
            return (
                <div className="video">
                    <iframe
                        title="playlist on youtube"
                        src={videoSrcURL+params}
                        height={"100%"}
                        width={"100%"}
                        frameBorder="0"
                        allow="autoplay" />
                </div>
            )
        }
    }

    const videoSrcURL = data.dataJson.embed
    const params = "?&autoplay=1&loop=1&mute=1&showinfo=0&controls=0&disablekb=1&modestbranding=1&color=white&fs=0&iv_load_policy=3"

    return (
        <Title_screen>
            {ElementToRender()}
            <Content>
                <div>
                    <Buttons />
                </div>
            </Content>
        </Title_screen>
    )
}
