import React from "react"

import Layout from "../components/layout"
import TitleScreen from "../screen/TitleScreen"
import Services from "../screen/Services"
import Contact from "../screen/Contact"

export default function Home() {
  return (
    <Layout>
      <TitleScreen />
      <Services />
      <Contact />
    </Layout>
  )
}
